import { Component, Input, Output, EventEmitter, HostListener, ElementRef, ContentChildren, QueryList, OnChanges, SimpleChanges, AfterContentInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { C4gSelectOptionComponent } from '../c4g-select-option/c4g-select-option.component';

@Component({
  selector: 'c4g-select',
  templateUrl: './c4g-select.component.html',
  styleUrls: ['./c4g-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: C4gSelectComponent,
      multi: true
    }
  ]
})
export class C4gSelectComponent implements ControlValueAccessor, OnChanges, AfterContentInit {
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() value: any = null;
  @Output() valueChange = new EventEmitter<any>();
  @ContentChildren(C4gSelectOptionComponent, { descendants: true })
  options: QueryList<C4gSelectOptionComponent>;

  isOpen = false;
  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private elementRef: ElementRef) {}

  ngAfterContentInit() {
    if (this.options) {
      this.options.forEach(option => {
        option.selectedChange.subscribe((value: any) => {
          this.setValue(value);
        });
      });

      this.options.changes.subscribe(() => {
        this.updateSelectedOption();
        // Resubscribe to any new options
        this.options.forEach(option => {
          option.selectedChange.subscribe((value: any) => {
            this.setValue(value);
          });
        });
      });
    }
    this.updateSelectedOption();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.updateSelectedOption();
    }
  }

  private updateSelectedOption() {
    if (this.options) {
      this.options.forEach(option => {
        option.isSelected = this.compareValues(option.value, this.value);
      });
    }
  }

  private compareValues(val1: any, val2: any): boolean {
    if (val1 === null && val2 === null) return true;
    if (val1 === undefined && val2 === undefined) return true;
    if (val1 === null || val2 === null) return false;
    if (val1 === undefined || val2 === undefined) return false;
    return val1 === val2;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  toggleDropdown(event: Event) {
    event.stopPropagation();
    this.isOpen = !this.isOpen;
  }

  writeValue(value: any): void {
    this.value = value;
    this.updateSelectedOption();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setValue(value: any) {
    this.value = value;
    this.onChange(value);
    this.onTouch();
    this.valueChange.emit(value);
    this.isOpen = false;
    this.updateSelectedOption();
  }
}
