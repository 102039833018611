<div class="c4g-select-container">
  <div class="c4g-select-label" *ngIf="label">{{label}}</div>
  <div class="c4g-select-trigger" (click)="toggleDropdown($event)">
    <div class="c4g-select-value">
      <ng-content select="[c4g-select-value]"></ng-content>
    </div>
    <div class="c4g-select-arrow" [class.open]="isOpen">
      <svg viewBox="0 0 24 24">
        <path d="M7 10l5 5 5-5z"/>
      </svg>
    </div>
  </div>

  <div class="c4g-select-panel" *ngIf="isOpen">
    <div class="c4g-select-content">
      <ng-content select="[c4g-select-options]"></ng-content>
    </div>
  </div>
</div>
