import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { firstValueFrom, Observable } from "rxjs";
import { ENVSETTINGS } from "./environment";
import {
  AppointmentExtensionModel,
  AppointmentExtensionResult,
  AppointmentModel,
  BookingUtil,
  CareBookModel,
  CareSeekerModel,
  CreditCardModel,
  CsBankModel
} from "@nx-c4g/c4g-models";
import { notify } from "@nx-c4g/c4g-ui";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AgendaService {


  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    @Inject(ENVSETTINGS) private environment: any
  ) {
  }

  isAdmin(): boolean {
    // Get from browser session storage isliob
    const isliob = sessionStorage.getItem('isliob');
    if (isliob) {
      return isliob === 'true';
    }
    return false;
  }

  async cancelCarebookBooking(careBookId: string, appt: AppointmentModel, clear: boolean): Promise<boolean> {
    if (!BookingUtil.canDelete(appt, this.isAdmin())) {
      console.error(this.translateService.instant('carebook.nodelete'));
      notify(this.translateService.instant('carebook.nodelete'), 'error')
      return false;
    }
    console.log('Deleting carebook schedule', careBookId, appt._id);
    const url = clear ? `${this.environment.api}care-book/appointment/delete/${appt._id}/clear` : `${this.environment.api}care-book/appointment/delete/${appt._id}`;
    try {
      await firstValueFrom(this.http.delete(url) as Observable<CareBookModel>);
      return true;
    } catch (error) {
      notify(this.translateService.instant('carebook.nodelete'), 'error')
      return false;
    }
  }

  async cancelCarebookBookingbyCG(careBookId: string, appt: AppointmentModel): Promise<boolean> {
    if (!BookingUtil.canDelete(appt, this.isAdmin())) {
      console.error(this.translateService.instant('carebook.nodelete'));
      notify(this.translateService.instant('carebook.nodelete'), 'error')
      return false;
    }
    console.log('Deleting carebook schedule', careBookId, appt._id);
    const url = `${this.environment.api}care-book/appointment/delete-by-cg/${appt._id}/clear`;
    try {
      await firstValueFrom(this.http.delete(url) as Observable<CareBookModel>);
      return true;
    } catch (error: any) {
      notify(this.translateService.instant('carebook.nodelete') + ': ' + error.error, 'error')
      return false;
    }
  }

  async cancelCGBooking(appt: AppointmentModel) {
    console.log('AgendaService cancelCGBooking', this.environment.api);

    try {
      const result = await firstValueFrom(this.http
        .delete(
          this.environment.api +
          'booking' +
          `/bycg/${appt._id}`
        ));
      return result;
    } catch (e) {
      return false;
    }
  }

  async cancelCSBooking(appt: AppointmentModel) {
    console.log('AgendaService cancelCSBooking', this.environment.api);
    try {
      const result = await firstValueFrom(this.http
        .delete(
          this.environment.api +
          'booking' +
          `/bycs/${appt._id}`
        ));
      return result;
    } catch (e) {
      return false;
    }
  }



  async removeBlock(appt: AppointmentModel) {
    try {
      console.log('removeBlock', appt);
      await firstValueFrom(this.http.delete(this.environment.api + 'cg-avail/' + appt._id));
      return true;
    } catch (e) {
      return false;
    }
  }

  async acceptBooking(appt: AppointmentModel) {
    try {
      await firstValueFrom(this.http.get(this.environment.api + 'booking/confirm/' + appt._id));
      return true;
    } catch (e) {
      return false;
    }
  }

  async rejectBooking(appt: AppointmentModel) {
    try {
      await firstValueFrom(this.http.get(this.environment.api + 'booking/reject/' + appt._id));
      return true;
    } catch (e) {
      return false;
    }
  }

  async getBooking(bookingId: string) {
    try {
      const url = this.environment.api + 'booking/' + bookingId;
      console.log('getBooking', url);
      const result = await firstValueFrom(this.http
        .get<AppointmentModel>(url
        ));
      return result;
    } catch (e) {
      return null;
    }
  }

  async getBalance(careSeeker: CareSeekerModel) {
    return await firstValueFrom(this.http.get<CsBankModel>(this.environment.api + 'bank'));
  }

  async createCustomerAccount(csProfile: CareSeekerModel) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.environment.api + 'payment/createcustomer', csProfile)
        .toPromise()
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            console.error('Error', error);
            reject(error);
          }
        );
    });
  }

  async depositCC(amount: number) {
    return new Promise<CsBankModel>((resolve, reject) => {
      this.http
        .put<CsBankModel>(this.environment.api + 'bank/direct-deposit', {
          type: 'deposit',
          amount: amount,
          reference: '',
        })
        .toPromise()
        .then((r: CsBankModel) => {
          resolve(r);
        });
    });
  }

  async saveCard(card: CreditCardModel) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.environment.api + 'payment/save-card', card)
        .toPromise()
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async extendAppointment(extension: AppointmentExtensionModel): Promise<{appt: AppointmentModel, result: AppointmentExtensionResult}>{
    return firstValueFrom(this.http.put(this.environment.api + 'booking/extend', extension) as Observable<{appt: AppointmentModel, result: AppointmentExtensionResult}>);
  }
}
