import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'c4g-select-option',
  templateUrl: './c4g-select-option.component.html',
  styleUrls: ['./c4g-select-option.component.scss']
})
export class C4gSelectOptionComponent {
  @Input() value: any;
  @Input() isAction = false;
  @Output() selectedChange = new EventEmitter<any>();
  @Output() actionClick = new EventEmitter<void>();

  private _isSelected = false;

  get isSelected(): boolean {
    return this._isSelected;
  }

  set isSelected(value: boolean) {
    this._isSelected = value;
  }

  select(event: Event) {
    event.stopPropagation();
    if (this.isAction) {
      this.actionClick.emit();
    } else {
      this.selectedChange.emit(this.value);
    }
  }
}
