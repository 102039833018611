<div fxLayout="column">
  <div class="outline"
       [class]="errorClass()">
    <c4g-address-display class="full-width"
                         [processedAddress]="processedAddress"
                         [canEdit]="true"
                         (editAddress)="openDialog(placeDialog)"></c4g-address-display>
  </div>
</div>

<ng-template #placeDialog>
  <mat-card fxLayout="column"
            class="full-width">
    <button mat-raised-button (click)="switchMode()" class="mb-2">{{isEasyMode ? 'Form Mode' : 'Easy Mode'}}</button>
    <div *ngIf="isEasyMode">
      <mat-form-field class="full-width">
        <mat-label>Search Address</mat-label>
        <input matInput [(ngModel)]="easyModeSearchText" (keyup.enter)="onEasyModeSearch()">
        <button mat-mini-fab color="primary" (click)="onEasyModeSearch()" matSuffix>
          <mat-icon class="search-icon">search</mat-icon>
        </button>
      </mat-form-field>
      <h3>Select from the search result below</h3>
      <mat-list *ngIf="easyModeResults.length > 0">
        <mat-list-item *ngFor="let result of easyModeResults" (click)="onEasyModeSelect(result)">
          {{ result.formatted_address }}
        </mat-list-item>
      </mat-list>
      <div *ngIf="easyModeResults.length === 0">
        <p>No results found</p>
      </div>
    </div>
    <div *ngIf="!isEasyMode">
      <c4g-address-display class="full-width"
                           [processedAddress]="tempAddress"
                           [canEdit]="false"></c4g-address-display>

      <div fxLayout="row wrap"
           fxLayoutGap="8px">

        <mat-form-field>
          <mat-label>{{"forms.country" | translate}}</mat-label>
          <mat-select [(ngModel)]="selectedCountry"
                      (selectionChange)="onCountrySelect($event.value)">
            <mat-option *ngFor="let country of countries"
                        [value]="country">
              {{ country.name | uppercase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="selectedCountry">
          <mat-label>{{"forms.state" | translate}}</mat-label>
          <mat-select [(ngModel)]="selectedState"
                      (selectionChange)="onStateSelect($event.value)">
            <mat-option *ngFor="let state of selectedCountry.states"
                        [value]="state">
              {{ state.name | uppercase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width"
                        *ngIf="selectedState">
          <mat-label>{{"forms.searchCity" | translate}}</mat-label>
          <input type="text"
                 matInput
                 [(ngModel)]="citySearch"
                 name="name"
                 ngDefaultControl
                 [matAutocomplete]="city">
          <mat-autocomplete autoActiveFirstOption
                            #city="matAutocomplete"
                            name="name"
                            ngDefaultControl
                            [(ngModel)]="selectedCity"
                            (optionSelected)="onCitySearchSelect($event)">
            <mat-option *ngFor="let city of filteredCities()"
                        [value]="city">
              {{city.name | uppercase}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <!-- <mat-form-field *ngIf="selectedState">
          <mat-label>{{"forms.city" | translate}}</mat-label>
          <mat-select [(ngModel)]="selectedCity"
                      (selectionChange)="onCitySelect($event.value)">
            <mat-option *ngFor="let city of selectedState.cities"
                        [value]="city">
              {{ city.name | uppercase }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        <mat-form-field *ngIf="selectedCountry && selectedState && selectedCity">
          <mat-label>{{"forms.address" | translate}}</mat-label>
          <input type="text"
                 matInput
                 [(ngModel)]="tempAddress.Street"
                 (ngModelChange)="onStreetChange($event)"
                 name="name"
                 ngDefaultControl>
        </mat-form-field>
        <mat-form-field *ngIf="selectedCountry && selectedState && selectedCity">
          <mat-label>{{"forms.address2" | translate}}</mat-label>
          <input type="text"
                 matInput
                 [(ngModel)]="tempAddress.Line3"
                 name="name"
                 ngDefaultControl>
        </mat-form-field>
        <mat-form-field *ngIf="selectedCountry && selectedState && selectedCity">
          <mat-label>{{"forms.postalCode" | translate}}</mat-label>
          <input type="text"
                 matInput
                 [(ngModel)]="tempAddress.PostalCode"
                 (ngModelChange)="onPostalCodeChange($event)"
                 name="name"
                 ngDefaultControl>
        </mat-form-field>
        <!-- <button mat-raised-button
                color="primary"
                (click)="locate(tempAddress)">
          {{"buttons.locate" | translate}}
        </button>
        <p class="label">{{"forms.locate" | translate}}</p>
        <div *ngIf="geoEnabled"
             #mapContainer
             class="map-container">
          <mat-spinner *ngIf="!mapLoaded"></mat-spinner>
        </div> -->
        <div *ngIf="!geoEnabled"
             class="map-container center-error"
             fxLayout="column">You must enable Geo Location to use
          this
          application. The application will NEVER track you, we just need proper coordinates to provide the services at
          the
          right location. <br><br><b>Please Enable Geo Location and REFRESH the application.</b>
        </div>
      </div>
    </div>
    <mat-card-footer fxLayoutGap="8px" *ngIf="!isEasyMode"
                     class="mt-3">
      <button mat-raised-button
              color="primary"
              (click)="validateAddressWithGeo()"
              [disabled]="hasErrors(tempAddress, true)">
        {{"buttons.save" | translate}}
      </button>
      <button mat-raised-button
              color="warn"
              (click)="cancel()">{{"buttons.cancel" | translate}}</button>
    </mat-card-footer>
  </mat-card>

</ng-template>


<ng-template #locationDialog>
  <mat-card fxLayout="column"
            class="full-width">
    <mat-card-footer fxLayoutGap="8px"
                     class="mt-3">
      <button mat-raised-button
              color="primary"
              (click)="save()"
              [disabled]="hasErrors(tempAddress)">
        {{"buttons.save" | translate}}
      </button>
      <button mat-raised-button
              color="warn"
              (click)="cancel()">{{"buttons.cancel" | translate}}</button>
    </mat-card-footer>
  </mat-card>
</ng-template>

<ng-template #partialDialog>
  <mat-card fxLayout="column"
            class="full-width">
    <h1>Address not found</h1>
    <mat-form-field>
      <mat-label>Select an address</mat-label>
      <mat-select [(ngModel)]="selectedPartialAddress">
        <mat-option *ngFor="let address of partialAddressesFound"
                    [value]="address">
          {{ address.formatted_address }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-card-footer fxLayoutGap="8px"
                     class="mt-3">
      <button mat-raised-button
              color="primary"
              (click)="savePartial()"
              [disabled]="!selectedPartialAddress">
        {{"buttons.save" | translate}}
      </button>
      <button mat-raised-button
              color="warn"
              (click)="closePartial()">{{"buttons.cancel" | translate}}</button>
    </mat-card-footer>
  </mat-card>
</ng-template>

<ng-template #addressValidationDialog>
  <mat-card fxLayout="column" class="full-width">
    <h1>Validate Address</h1>
    <p>{{ addressToValidate }}</p>
    <div fxLayout="row wrap" fxLayoutGap="8px">
      <button mat-raised-button color="primary" (click)="addressValidated()" [disabled]="isProcessingAddress">
        Accept
      </button>
      <button mat-raised-button color="warn" (click)="addressRejected()" [disabled]="isProcessingAddress">
        Reject
      </button>
      <button *ngIf="!showMapSelected" mat-raised-button color="accent" (click)="showMap(locationToValidate)">
        Show Map
      </button>
    </div>
    <div *ngIf="showMapSelected" #mapContainer class="map-container">
      <mat-spinner *ngIf="!mapLoaded || isProcessingAddress"></mat-spinner>
    </div>
  </mat-card>
</ng-template>

<ng-template #apartmentDialog>
  <mat-card fxLayout="column" class="full-width">
    <h1>Apartment Number</h1>
    <p>Do you have an apartment number?</p>
    <mat-form-field>
      <mat-label>Apartment Number</mat-label>
      <input matInput [(ngModel)]="tempApartmentNumber" placeholder="Optional">
    </mat-form-field>
    <div fxLayout="row wrap" fxLayoutGap="8px">
      <button mat-raised-button color="primary" (click)="saveWithApartment()">
        Save
      </button>
      <button mat-raised-button color="warn" (click)="skipApartment()">
        Skip
      </button>
    </div>
  </mat-card>
</ng-template>
