<div class="dialog-container">
  <div class="dialog-content">
    <div class="dialog-header">
      <h2>{{ title }}</h2>
      <button *ngIf="showCloseButton" mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="dialog-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
