<div *ngIf="!(displayedAppointment.checkInOut && displayedAppointment.checkInOut.checkOut)"
     class="p-2 mb-2">
  <div fxLayout="row">
    <div>
      <button mat-mini-fab
              color="accent"
              class="mr-3"
              aria-label="Refresh button"
              (click)="info()">
        <mat-icon>location_on</mat-icon>
      </button>
    </div>
    <div>
      <h2 *ngIf="!isToday(displayedAppointment.startDate) && !isToday(displayedAppointment.endDate)">{{"tomorrow" | translate}}</h2>
      <h3 class="noPads noMargin">
        {{ "bookingWith" | translate }}
        {{ name }}
      </h3>
      <h3 class="noPads noMargin">
        {{ displayedAppointment.startDate | date: 'shortTime' }} -
        {{ displayedAppointment.endDate | date: 'shortTime' }}
      </h3>
      <div>{{ displayedAppointment.address }}</div>
      <div class="button-bar mt-3"
           *ngIf="isToday(displayedAppointment.startDate) || isWithinFirst3HoursOfTomorrow(displayedAppointment.startDate) || isToday(displayedAppointment.endDate) "
           fxLayout="row"
           fxLayoutGap="8px">
        <button mat-raised-button
                color="accent"
                (click)="in()"
                *ngIf="!displayedAppointment.checkInOut && !displayedAppointment.errorCheckInOut && !inDisabled">
          Check-In
        </button>
        <mat-spinner [diameter]="20"
                     *ngIf="inDisabled"></mat-spinner>
        <button mat-raised-button
                color="accent"
                *ngIf="(displayedAppointment.checkInOut && displayedAppointment.checkInOut.checkIn) || (displayedAppointment.errorCheckInOut && displayedAppointment.errorCheckInOut.checkIn)"
                [disabled]="true">
                @if (displayedAppointment.checkInOut && displayedAppointment.checkInOut.checkIn) {
                  {{ displayedAppointment.checkInOut.checkIn.time | date: 'shortTime' }}
                } @else {
                  {{ displayedAppointment.errorCheckInOut.checkIn.time | date: 'shortTime' }}
                }
        </button>
        <button mat-raised-button
                color="warn"
                (click)="out()"
                *ngIf="((displayedAppointment.checkInOut?.checkIn || displayedAppointment.errorCheckInOut?.checkIn) && !(displayedAppointment.checkInOut?.checkOut || displayedAppointment.errorCheckInOut?.checkOut)) && !outDisabled">
          Check-Out
        </button>
        <mat-spinner [diameter]="20"
                     *ngIf="outDisabled"></mat-spinner>

        <button mat-raised-button
                color="warn"
                *ngIf="(displayedAppointment.checkInOut && displayedAppointment.checkInOut.checkOut) || (displayedAppointment.errorCheckInOut && displayedAppointment.errorCheckInOut.checkOut)"
                [disabled]="true">
                @if (displayedAppointment.checkInOut && displayedAppointment.checkInOut.checkOut) {
                  {{ displayedAppointment.checkInOut.checkOut.time | date: 'shortTime' }}
                } @else {
                  {{ displayedAppointment.errorCheckInOut.checkOut.time | date: 'shortTime' }}
                }
        </button>

      </div>
      <div class="warn"
           *ngIf="isToday(displayedAppointment.startDate) || isToday(displayedAppointment.endDate)">{{"processing-fee" | translate}}</div>

    </div>
  </div>

</div>
