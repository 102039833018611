import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, Type } from '@angular/core';

export interface OverlayDialogConfig<D = any> {
  data?: D;
  closeOnClickOutside?: boolean;
}

export interface OverlayDialogRef<T = any> {
  componentInstance: T;
  close(result?: any): void;
}

@Injectable({
  providedIn: 'root'
})
export class OverlayDialogService {
  private dialogComponentRef: ComponentRef<any>;
  private resolvePromise: (value: any) => void;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  open<T, D = any, R = any>(component: Type<T>, config: OverlayDialogConfig<D> = {}): Promise<R> {
    this.closeExistingDialog();

    const promise = new Promise<R>((resolve) => {
      this.resolvePromise = resolve;
    });

    // Create component
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.dialogComponentRef = componentFactory.create(this.injector);

    // Set data if provided
    if (config.data) {
      this.dialogComponentRef.instance.data = config.data;
    }

    // Set closeOnClickOutside if provided
    if (config.closeOnClickOutside !== undefined) {
      this.dialogComponentRef.instance.closeOnClickOutside = config.closeOnClickOutside;
    }

    // Subscribe to dialog close events
    if (this.dialogComponentRef.instance.closeDialog) {
      this.dialogComponentRef.instance.closeDialog.subscribe((result: R) => {
        this.close(result);
      });
    }

    // Attach to app
    this.appRef.attachView(this.dialogComponentRef.hostView);

    // Get DOM element
    const domElem = (this.dialogComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0];

    // Add to body
    document.body.appendChild(domElem);

    // Prevent body scroll
    document.body.style.overflow = 'hidden';

    return promise;
  }

  private closeExistingDialog() {
    if (this.dialogComponentRef) {
      this.close();
    }
  }

  private close(result?: any): void {
    if (this.dialogComponentRef) {
      this.appRef.detachView(this.dialogComponentRef.hostView);
      this.dialogComponentRef.destroy();
      document.body.style.overflow = 'auto';
      this.resolvePromise(result);
      this.dialogComponentRef = null;
    }
  }
}
