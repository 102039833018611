import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'c4g-overlay-dialog',
  templateUrl: './overlay-dialog.component.html',
  styleUrls: ['./overlay-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class OverlayDialogComponent {
  @Input() title = '';
  @Input() showCloseButton = true;
  @Input() closeOnClickOutside = true;
  @Output() closeDialog = new EventEmitter<void>();

  onClose() {
    this.closeDialog.emit();
  }

  @HostListener('click', ['$event'])
  onHostClick(event: MouseEvent) {
    // Only close if clicking the overlay background (host element) and closeOnClickOutside is true
    if (event.target === event.currentTarget && this.closeOnClickOutside) {
      this.onClose();
    }
  }
}
